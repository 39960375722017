/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthConfigRepositoryService, AuthService } from 'baseflow-auth';
import { ToastrModule } from 'ngx-toastr';
import { AuthInitializerFactory, MAP_AUTH_CONFIG, SharedModule } from 'shared';

import buildVersion from '../assets/build-version.json';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppInitializerFactory, MapAuthConfigFactory, TranslateLoaderFactory } from './factories';
import { MtpCommonModule } from './mtp-common/mtp-common.module';
import { AppSettingsService } from './mtp-common/services/app-settings.service';
import { AppStateService } from './mtp-common/services/state/app-state.service';
import { MtpRoutingModule } from './mtp-routing/mtp-routing.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        // NG modules
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        // NoopAnimationsModule,
        // Package modules
        SharedModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [],
            },
        }),
        // Application modules
        MtpCommonModule,
        MtpRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.env !== 'DEV' }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitializerFactory,
            deps: [TranslateService, AppSettingsService, AppStateService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: AuthInitializerFactory,
            deps: [AppSettingsService, AuthConfigRepositoryService, MAP_AUTH_CONFIG, AuthService],
            multi: true,
        },
        {
            provide: MAP_AUTH_CONFIG,
            useValue: MapAuthConfigFactory,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    public constructor() {
        console.log(`Initializing OneGo Web Client v${buildVersion.buildVersion}...`);
    }
}
